import request from './request'
// QueryDateSlotDto
import { QueryPageDto } from './dto'

// 获取客户列表
export interface ClientDto {
    client_id: number
    name: string
    short_name: string
    email: string
    license_image_id: string
    account_id: string
    actual_money: string
    company_name: string
    create_time: string
    id: number
    invoice_date: string
    invoice_subject: string
    remark: string
    tax_number: string
    rank: string
    is_refused: number
    can_debt: string
    level5_max_ratio: number
    age_50_65_max_ratio: number
    age_66_70_max_ratio: number
    sales: {
        name: string
        sales_id: string
    }
    service: {
        name: string
        sales_id: string
    }
}
interface GetClientDto extends QueryPageDto {
    salesId?: number
    balanceState?: string
    rank?: string
    orderBy?: string
    sort?: string
}
export function getClientRecordList (
    params: GetClientDto
): Promise<{ data: ClientDto[]; total: number }> {
    return request.get('/client/list', {
        params
    })
}

// 财务客户列表
export interface FinanceClientDto {
    account_id: number
    balance: string
    client_id: number
    create_time: string
    email: string
    invoice_sum: number
    invoice_surplus: number
    license_image_id: number
    name: string
    premium_sum: number
    recharge_sum: number
    sales_id: number
    tax_number: string
}
export function getClientForFinance (
    params: GetClientDto
): Promise<{ data: FinanceClientDto[]; total: number }> {
    return request.get('/client/listForFinance', {
        params
    })
}

interface AddClientReqDto {
    name: string
    shortName: string
    taxNumber: string
    email: string
    mobile: string
    licenseImageId: string
    accountId: string
    salesId: string
}

// 创建客户
export function addClient (data: AddClientReqDto): Promise<void> {
    return request.post('/client/add', data)
}

// 修改客户
export function editClient (data: AddClientReqDto): Promise<void> {
    return request.post('/client/edit', data)
}

// 获取客户平台地址
export function clientWebToken (params: {
    clientId: string
}): Promise<{ url: string }> {
    return request.get('/client/enter', {
        params
    })
}

// 设置客户拒保状态
export function setRefuse (data: { clientId: number }): Promise<void> {
    return request.post('/client/refuse', data)
}
