
import { defineComponent } from 'vue'
import {
    getOrderPdfRecord,
    OrderPdfRecordDto
} from '@/api/orderPdf'

export default defineComponent({
    name: 'OrderPdf',
    data () {
        return {
            searchValue: '',
            searchText: '',
            pageSize: 10,
            pageNum: 1,
            total: 0,
            slotdate: [] as string[],
            searchdate: [] as string[],
            tableData: [] as OrderPdfRecordDto[]
        }
    },
    async created () {
        this.handleCurrentChange()
    },
    methods: {
        async handleCurrentChange () {
            const result = await getOrderPdfRecord({
                pageSize: this.pageSize,
                page: this.pageNum,
                startTime: this.searchdate[0] || '',
                endTime: this.searchdate[1] || '',
                search: this.searchValue
            })
            this.total = result.total
            this.tableData = result.data
        },
        handleSizeChange () {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search () {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.searchdate = this.slotdate || []
            this.handleCurrentChange()
        },
        downloadFile (url : string) {
            window.open(url)
            this.$message.success('下载成功！')
        }

    }
})
