import request from './request'
import { QueryPageDto, QueryDateSlotDto } from './dto'

interface GetOrderPdfRecordDto extends QueryPageDto, QueryDateSlotDto {

}

export interface OrderPdfRecordDto {
  pdf_id: number
  title: string
  file: string
  file_nostamp: number
  create_time: string
  client: {
    client_id: number
    create_time: string
    name: string
  }

}

export function getOrderPdfRecord (
    params: GetOrderPdfRecordDto
): Promise<{ data: OrderPdfRecordDto[]; total: number }> {
    return request.get('/order_pdf/list', {
        params
    })
}

// 生成pdf
export function makePdf (data: { policyId: number, month:string, policyPersonIds:string[] }): Promise<void> {
    return request.post('/order_pdf/make', data)
}
