
import { defineComponent } from 'vue'
import { FinanceClientDto, getClientForFinance } from '@/api/client'
import { getPolicyPersonList } from '@/api/policy'
import { getPolicyList, PolicyDto } from '@/api/insurance'
import { makePdf } from '@/api/orderPdf'
import { generateMonthOptions } from '@/utils/util'
import { getDispatch } from '@/api/dispatch'

interface MonthOption {
  label: string;
  value: string;
}

export default defineComponent({
    name: 'OrderPdf',
    data () {
        return {
            selectCompanyIds: [],
            selectCompany: [] as any,
            clientId: 0,
            clientText: '',
            policyId: 0,
            policyText: '',
            searchText: '',
            month: '',
            onlyActive: '0',
            monthOptions: [] as MonthOption[], // 最近三个月的选项
            personsData: [] as {
        key: string
        label: string
      }[], // 当月人员
            persons: [] as string[] // 生成证明的人员
        }
    },
    async created () {
        this.generateOptions()
        await this.getCompanyOption()
    },
    methods: {
        async clientAuto (
            queryString: string,
            cb: (
        val: {
          value: string
          id: number
        }[]
      ) => void
        ): Promise<void> {
            const result = await getClientForFinance(
                {
                    page: 1,
                    search: queryString,
                    pageSize: 10
                }
            )
            cb(
                result.data
                    .map((item: FinanceClientDto) => {
                        return {
                            value: item.name,
                            id: item.client_id
                        }
                    })
                    .filter((item: { value: string }) =>
                        item.value.includes(queryString)
                    )
            )
        },
        // 选择客户
        selectClient (item: { value: string; id: number }) {
            this.clientText = item.value
            this.clientId = item.id
        },

        async getCompanyOption () {
            const result = await getDispatch({
                page: 1,
                pageSize: 300
            })
            this.selectCompany = result.data
        },
        async policyAuto (
            queryString: string,
            cb: (
        val: {
          value: string
          id: number
        }[]
      ) => void
        ): Promise<void> {
            const result = await getPolicyList(
                {
                    page: 1,
                    clientId: this.clientId.toString(),
                    pageSize: 20
                }
            )
            cb(
                result.map((item: PolicyDto) => {
                    return {
                        value: item.plan.name + '-' + item.plan.type + '(' + item.active_person_count + '人)',
                        id: item.policy_id
                    }
                })
                    .filter((item: { value: string }) =>
                        item.value.includes(queryString)
                    )
            )
        },
        // 选择保单
        selectPolicy (item: { value: string; id: number }) {
            this.policyText = item.value
            this.policyId = item.id
        },
        generateOptions () {
            const months = generateMonthOptions(2)
            months.forEach((item: any) => {
                this.monthOptions.push({
                    label: item,
                    value: item
                })
            })
        },
        async search () {
            // if (!this.clientId) {
            //     this.$message.warning('请选择客户')
            //     return
            // }
            if (!this.policyId) {
                this.$message.warning('请选择保单')
                return
            }
            if (!this.month) {
                this.$message.warning('请选择月份')
                return
            }
            // 根据客户id和月份获取当月人员
            const result = await getPolicyPersonList({
                search: this.searchText,
                policyId: this.policyId,
                month: this.month,
                realActive: this.onlyActive,
                selectCompanyIds: this.selectCompanyIds,
                page: 1,
                pageSize: 6000
            })
            const list = [] as { key: string; label: string }[]
            for (const item of result.data) {
                list.push({
                    key: item.policy_person_id.toString(),
                    label: `${item.employee.name} - ${item.company_name} - ${item.occupation_name} [${item.occupation_level}类] - ${
                        item.real_start_date
                    }加保 `
                })
            }
            this.personsData = list
        },
        async onSubmit () {
            if (this.persons.length === 0) {
                this.$message.warning('请选择人员')
                return
            }

            await makePdf({
                policyId: this.policyId,
                month: this.month,
                policyPersonIds: this.persons
            })
            await this.$message.success('申请已提交，请稍后在投保证明列表查看')
            await this.$router.push({
                path: '/order-pdf'
            })
        }
    }
})
