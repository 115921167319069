import request from './request'
// QueryDateSlotDto
import { QueryPageDto } from './dto'

// 获取客户列表
export interface PolicyPersonDto {
  policy_person_id: number;
  employee_id: number;
  start_date: string;
  real_start_date: string;
  end_date: string;
  occupation_id: number;
  occupation_name: string;
  occupation_level: number;
  origin_occupation_level: number;
  company_id: number;
  company_name: string;
  employee: {
    employee_id: string
    name: string
    idcard: string
  }
}

interface GetPolicyPersonDto extends QueryPageDto {
  search?: string
  selectCompanyIds?: []|any
  clientId?: number
  policyId?: number
  month?: string
  onlyActive?: string
  realActive?: string
}

export function getPolicyPersonList (
    params: GetPolicyPersonDto
): Promise<{ data: PolicyPersonDto[]; total: number }> {
    return request.get('/policy.person/list', {
        params
    })
}

interface AddPolicyNumberDto {
  supplier: string
  month: string
  numbers: {
    company: string
    plan_id: string
    policy_number: string
  }[]
}
interface GetPolicyNumberDto {
  plan_id: string
  company: string
  month:string
  number:string
  }

export function addPolicyNumber (data: AddPolicyNumberDto): Promise<void> {
    return request.post('/policy.number/saveData', data)
}
export function getPolicyNumber (
    params: { 'month': string }
): Promise< GetPolicyNumberDto[] > {
    return request.get('/policy.number/getData', {
        params
    })
}
